var render = function render(){var _vm=this,_c=_vm._self._c;return _c('ul',{class:['products-list', `list-${_vm.listId}`],attrs:{"data-test-id":`PRODUCT_LIST-${_vm.listId}`}},_vm._l((_vm.products),function(product){return _c('li',{key:`item-${product.productIndex}`,staticClass:"product-item"},[_c('ProductCardSmall',{key:`products-list-item-${product.productIndex}`,staticClass:"product",class:_vm.checkSponsoredInRow(
                    product.productIndex,
                    product.isSponsored
                ),attrs:{"data-product-card":product.productIndex,"product":product.designSystem,"color-variants":_vm.colorVariants[product.sku],"is-sponsored":product.isSponsored,"is-favourite":_vm.isOnWishlist(product.sku),"is-picture-lazy":product.productIndex > 4,"picture-fetch-priority":_vm.getFetchPriority(product.productIndex),"picture-sizes":_vm.listingProductPictureSizes,"is-link-enabled":false,"sponsored-label":_vm.$t('Sponsored'),"omnibus-minimal-label":_vm.$t('Lowest price'),"omnibus-regular-label":_vm.$t('Regular price'),"data-test-id":_vm.PRODUCT_LIST_ITEM},on:{"toggle-favourite":function($event){return _vm.toggleWishlist(product)}},nativeOn:{"click":[function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"left",37,$event.key,["Left","ArrowLeft"]))return null;if($event.ctrlKey||$event.shiftKey||$event.altKey||$event.metaKey)return null;if('button' in $event && $event.button !== 0)return null;return _vm.$emit('product-click', {
                    product,
                    mouseEvent: $event,
                    position: product.productIndex + 1,
                })},function($event){if(!$event.ctrlKey)return null;if($event.shiftKey||$event.altKey||$event.metaKey)return null;return _vm.$emit('product-click', {
                    product,
                    mouseEvent: $event,
                    position: product.productIndex + 1,
                })}],"mouseup":function($event){if($event.ctrlKey||$event.shiftKey||$event.altKey||$event.metaKey)return null;if('button' in $event && $event.button !== 1)return null;return _vm.$emit('product-click', {
                    product,
                    mouseEvent: {
                        ...$event,
                        middleKey: true,
                        position: product.productIndex + 1,
                    },
                })},"contextmenu":function($event){return _vm.$emit('product-open-contextmenu', { product })}}})],1)}),0)
}
var staticRenderFns = []

export { render, staticRenderFns }