import { ERROR_ACTION_TAG_NAME } from '@types/Errors';
import {
    DISPLAY_PLACEMENT_LISTING,
    DISPLAY_PLACEMENT_MARKETING_ACTIONS_LISTING,
} from '@configs/ad-tech';

export const prepareAdsDisplayData = async ({
    $services,
    $errorHandler,
    $ua,
    store,
    route,
}) => {
    const result = {
        marketingActionDisplayData: {},
        topListingDisplayData: {},
        crossListingDisplayData: {},
    };

    if (process.server && $ua?.isBot) {
        return result;
    }

    const {
        filters: { urlFilters },
        category: { query, url },
        config: {
            storeView: { locale },
        },
    } = store.state;

    const getDisplay = async placement => {
        try {
            const adsDisplayData = await $services.adTech.getDisplay({
                payload: {
                    placement,
                    categoryPath: url,
                    sourceUrl: route.fullPath,
                    query: query ?? '',
                },
                locale,
            });

            const [marketingActionDisplayData = {}] =
                adsDisplayData.display || [];

            return marketingActionDisplayData;
        } catch (err) {
            $errorHandler.captureError(err, {
                [ERROR_ACTION_TAG_NAME]: 'catalog.getDisplay',
            });

            return {};
        }
    };

    const getDisplayWithPositions = async placement => {
        try {
            const adsDisplayWithPositionsData = await $services.adTech.getDisplayWithPositions(
                {
                    payload: {
                        placement,
                        categoryPath: url,
                        sourceUrl: route.fullPath,
                        query: query ?? '',
                        limit: 2,
                    },
                    locale,
                }
            );

            const {
                display: {
                    TOP: topListingDisplayData = {},
                    MIDDLE: crossListingDisplayData = {},
                } = {},
            } = adsDisplayWithPositionsData || {};

            return { topListingDisplayData, crossListingDisplayData };
        } catch (err) {
            $errorHandler.captureError(err, {
                [ERROR_ACTION_TAG_NAME]: 'catalog.getDisplayWithPositions',
            });

            return { topListingDisplayData: {}, crossListingDisplayData: {} };
        }
    };

    try {
        if ('akcja' in urlFilters) {
            const displayData = await getDisplay(
                DISPLAY_PLACEMENT_MARKETING_ACTIONS_LISTING
            );

            result.marketingActionDisplayData = displayData;
        } else {
            const {
                topListingDisplayData,
                crossListingDisplayData,
            } = await getDisplayWithPositions(DISPLAY_PLACEMENT_LISTING);

            result.topListingDisplayData = topListingDisplayData;
            result.crossListingDisplayData = crossListingDisplayData;
        }

        return result;
    } catch (err) {
        $errorHandler.captureError(err, {
            [ERROR_ACTION_TAG_NAME]: 'catalog.prepareAdsDisplay',
        });

        return result;
    }
};
