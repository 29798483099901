import {
    DEFAULT_IMAGE_FORMAT,
    IMAGE_FORMAT_WEBP_EXTENSION,
    IMAGE_FORMAT_WEBP_MIME_TYPE,
    IMAGE_TYPE_PRODUCT_256w_256h,
    IMAGE_TYPE_PRODUCT_512w_512h,
    PRODUCT_IMAGE_TYPES,
} from '@types/Image';
import { FETCH_PRIORITY } from '@types/FetchPriority';

import { getProductImage, getSrcsetsFromSourcesConfig } from '@assets/images';
import { getStructuredDataBreadcrumbList } from '@assets/structured-data';

export const buildListingHead = ({
    seoMeta,
    seoLinks = [],
    firstProduct,
    breadcrumbs,
    $route,
    $absoluteUrl,
    $getMetaTagRobotsContent,
}) => {
    const { robots, title, description } = seoMeta;

    const link = [...seoLinks];

    const meta = [
        {
            hid: 'robots',
            name: 'robots',
            content: $getMetaTagRobotsContent(robots),
        },
        {
            hid: 'title',
            name: 'title',
            content: title,
        },
        {
            hid: 'og:title',
            property: 'og:title',
            content: title,
        },
        {
            hid: 'description',
            name: 'description',
            content: description,
        },
        {
            hid: 'og:description',
            property: 'og:description',
            content: description,
        },
        {
            hid: 'og:type',
            property: 'og:type',
            content: 'category',
        },
    ];

    if (
        typeof firstProduct?.images?.[0][IMAGE_TYPE_PRODUCT_512w_512h] !==
        'undefined'
    ) {
        const { width, height, url, alt } = firstProduct.images[0][
            IMAGE_TYPE_PRODUCT_512w_512h
        ];

        meta.push(
            {
                hid: 'og:image',
                property: 'og:image',
                content: url,
            },
            {
                hid: 'og:image:secure_url',
                property: 'og:image:secure_url',
                content: url,
            },
            {
                hid: 'og:image:width',
                property: 'og:image:width',
                content: width,
            },
            {
                hid: 'og:image:height',
                property: 'og:image:height',
                content: height,
            },
            {
                hid: 'og:image:alt',
                property: 'og:image:alt',
                content: alt,
            },
            {
                hid: 'og:image:type',
                property: 'og:image:type',
                content: DEFAULT_IMAGE_FORMAT.mimeType,
            }
        );

        const firstProductImage = getProductImage(
            firstProduct,
            0,
            [IMAGE_TYPE_PRODUCT_256w_256h, IMAGE_TYPE_PRODUCT_512w_512h],
            IMAGE_TYPE_PRODUCT_256w_256h
        );

        const firstWebpProductImageSource = getSrcsetsFromSourcesConfig(
            firstProductImage?.sources || {}
        )[IMAGE_FORMAT_WEBP_EXTENSION];

        if (firstWebpProductImageSource) {
            link.push({
                rel: 'preload',
                as: 'image',
                fetchpriority: FETCH_PRIORITY.HIGH,
                imagesrcset: firstWebpProductImageSource,
                imagesizes: `${PRODUCT_IMAGE_TYPES[IMAGE_TYPE_PRODUCT_256w_256h].width}px`,
                type: IMAGE_FORMAT_WEBP_MIME_TYPE,
            });
        }
    }

    return {
        titleTemplate: titleChunk => titleChunk,
        title,
        meta,
        link,
        htmlAttrs: {
            prefix: 'og: http://ogp.me/ns#',
        },

        script: [
            {
                hid: 'ldjson-schema-breadcrumbs',
                innerHTML: getStructuredDataBreadcrumbList(
                    breadcrumbs,
                    $route.fullPath,
                    $absoluteUrl
                ).toJSON(),

                type: 'application/ld+json',
            },
        ],

        __dangerouslyDisableSanitizersByTagID: {
            'ldjson-schema-breadcrumbs': ['innerHTML'],
        },
    };
};
