import { filtersToParamArray } from '@assets/path';

export const getSearchData = (
    selectedCodes = {},
    codes = [],
    selectedExtraParams = {},
    sort = {}
) => {
    const { orderCode, orderDir } = sort;

    const filters = Object.fromEntries(
        codes.map(code => {
            const value = selectedCodes[code];
            let joinedString = null;

            if (value) {
                joinedString = value.join(',');
            }

            return [`filter_${code}`, joinedString];
        })
    );

    const extraFilters = Object.values(selectedExtraParams).reduce(
        (acc, { code, selected }) => {
            const valuesString = Object.values(selected).map(
                ({ code: valueCode, url }) => {
                    if (valueCode === 'range') {
                        return url;
                    }

                    return valueCode;
                }
            );

            acc.filters[`filter_${code}`] = valuesString.join();
            acc.availableFilters.push(code);
            acc.selectedFilters.push(`${code}:${valuesString.join('-')}`);

            return acc;
        },
        { filters: {}, availableFilters: [], selectedFilters: [] }
    );

    return {
        filters: {
            ...filters,
            ...extraFilters.filters,
        },
        order: orderCode ? `${orderCode}_${orderDir}` : null,
        availableFilters: [...codes, ...extraFilters.availableFilters].join(),
        selectedFilters: [
            ...filtersToParamArray(selectedCodes),
            ...extraFilters.selectedFilters,
        ].join('|'),
    };
};
