import { generateHreflangs, generateSeoMeta } from '@eobuwie/nuxt-catalog-seo';

import {
    META_ROBOTS_NOINDEX_FOLLOW,
    META_ROBOTS_NOINDEX_NOFOLLOW,
} from '@configs/seo';
import { IS_HREFLANG_ALLOWED } from '@localeConfig/keys';

import { ERROR_ACTION_TAG_NAME } from '@types/Errors';

const categoriesIdsBlockedFromIndex = ['6277', '6278', '6279', '6280'];

const whitelistedFilters = [
    'cholewka',
    'kolekcja',
    'kolor',
    'manufacturer_with_collection',
    'product_badge',
    'rodzaj_obcasa',
    'rodzaj_zapiecia',
    'grouped_size',
    'styl',
    'technologie_entity',
    'wykonanie',
];

export const buildSeoForCatalog = ({
    $createCategoryPath,
    $absoluteUrl,
    $filterManager,
    $errorHandler,
    $t,
    store,
    category,
    route,
    total,
    navigation,
    breadcrumbs,
    limit,
    seomaticName,
    seomaticSubHeading,
    seomaticMeta,
    seomaticCanonicalUrl,
    currentPage = 1,
    urlFilters = {},
    filterLabels = {},
}) => {
    const { locale } = store.state.config.storeView;

    const totalPages = Math.ceil(total / limit);

    const isHreflangAllowedConfig =
        store.getters['config/localeConfigByKey'](IS_HREFLANG_ALLOWED) || {};

    const metaLinks = generateHreflangs(
        {
            hreflangs: navigation.hreflangs || [],
            breadcrumbs,
            storeViews: store.state.config.storeViews,
            config: isHreflangAllowedConfig,
        },
        $createCategoryPath
    );

    let seoParams = {};

    const selectedFilters = {
        ...$filterManager.selectedLabels,
        ...$filterManager.selectedExtraLabels,
    };

    try {
        seoParams = generateSeoMeta({
            meta: category.translations[locale],
            absoluteUrl: $absoluteUrl(route.path),
            query: route.query,
            selectedFilters,
            totalPages,
        });

        if (seoParams.errors?.length) {
            seoParams.errors.forEach(err => {
                $errorHandler.captureMessage(err, {
                    [ERROR_ACTION_TAG_NAME]: 'createCategorySeoParams',
                });
            });
        }
    } catch (err) {
        $errorHandler.captureMessage(err, {
            [ERROR_ACTION_TAG_NAME]: 'createCategorySeoParams',
        });
    }

    const { meta: searchMeta, heading: searchHeading } = seoParams || {};
    let { seoLinks } = seoParams || {};

    if (seomaticCanonicalUrl) {
        seoLinks = {
            ...seoLinks,
            canonical: {
                hid: 'canonical',
                rel: 'canonical',
                href: seomaticCanonicalUrl,
            },
        };
    }

    let seoTitle = seomaticMeta.title;

    if (currentPage > 1 && seoTitle) {
        seoTitle = `${seoTitle} - ${$t('Page')} ${currentPage}`;
    }

    let { robots } = searchMeta;

    const shouldSetNoIndex =
        Object.entries(urlFilters).some(([key, value]) => {
            const filter = Object.values(filterLabels).find(
                ({ url }) => url === key
            );

            if (!filter) {
                return true;
            }

            if (!whitelistedFilters.includes(filter.code)) {
                return true;
            }

            if (value.some(val => val.includes('%26') || val.includes('&'))) {
                return true;
            }

            return value.length !== filter.values.length;
        }) && robots !== META_ROBOTS_NOINDEX_NOFOLLOW;

    if (shouldSetNoIndex) {
        robots = META_ROBOTS_NOINDEX_FOLLOW;
    }

    const seoMeta = {
        title: seoTitle || searchMeta.title,
        description: seomaticMeta.description || searchMeta.description,
        robots: seomaticMeta.robots || robots,
    };

    const breadcrumbsIdsReversed = breadcrumbs.map(({ id }) => id).reverse();

    const isCategoryBlockedFromIndex = breadcrumbsIdsReversed.some(id =>
        categoriesIdsBlockedFromIndex.includes(id)
    );

    if (isCategoryBlockedFromIndex) {
        seoMeta.robots = META_ROBOTS_NOINDEX_NOFOLLOW;
    }

    const heading = seomaticName || searchHeading;

    const subHeading = seomaticSubHeading;

    const links = [...Object.values(seoLinks), ...metaLinks];

    return {
        totalPages,
        seoMeta,
        seoLinks: links,
        heading,
        subHeading,
    };
};
