<template>
    <div class="products-list-with-display">
        <ProductsList
            :products="productsSliced[0]"
            :products-color-variants="productsColorVariants"
            :list-id="1"
            @product-click="$emit('product-click', $event)"
            @product-open-contextmenu="
                $emit('product-open-contextmenu', $event)
            "
        />

        <CrossListingSizeFilter v-if="productsSliced[1].length" />

        <ProductsList
            v-if="productsSliced[1].length"
            :products="productsSliced[1]"
            :products-color-variants="productsColorVariants"
            :list-id="2"
            @product-click="$emit('product-click', $event)"
            @product-open-contextmenu="
                $emit('product-open-contextmenu', $event)
            "
        />

        <Display
            v-if="isCrossListingDisplay"
            :display="crossListingDisplay"
            class="cross-banner"
        />

        <ProductsList
            v-if="productsSliced[2].length"
            :products="productsSliced[2]"
            :products-color-variants="productsColorVariants"
            :list-id="3"
            @product-click="$emit('product-click', $event)"
            @product-open-contextmenu="
                $emit('product-open-contextmenu', $event)
            "
        />
    </div>
</template>

<script>
import ProductsList from '@search-organisms/ProductsList/ProductsList';
import CrossListingSizeFilter from '@search-organisms/CrossListingSizeFilter/CrossListingSizeFilter';

export default {
    name: 'ProductsListWithDisplay',

    components: {
        CrossListingSizeFilter,
        ProductsList,
        Display: () => ({
            component: import(
                /* webpackChunkName: "display" */ '@molecules/Display/Display'
            ),
        }),
    },

    props: {
        products: {
            type: Array,
            required: true,
        },

        productsColorVariants: {
            type: Object,
            required: true,
        },

        crossListingDisplay: {
            type: Object,
            default: () => ({}),
        },

        attributionToken: {
            type: [String, null],
            default: null,
        },
    },

    computed: {
        isCrossListingDisplay() {
            return Object.keys(this.crossListingDisplay).length;
        },

        productsSliced() {
            return this.products.reduce(
                (acc, product, index) => {
                    const updatedProduct = {
                        ...product,
                        productIndex: index,
                        designSystem: {
                            ...product.designSystem,
                            url: this.attributionToken
                                ? `${product.url}?token=${this.attributionToken}`
                                : product.url,
                        },
                    };

                    if (index < 12) {
                        acc[0].push(updatedProduct);
                    } else if (index < 24) {
                        acc[1].push(updatedProduct);
                    } else {
                        acc[2].push(updatedProduct);
                    }

                    return acc;
                },
                [[], [], []]
            );
        },
    },
};
</script>

<style scoped lang="scss">
.products-list-with-display {
    @apply border-y-1 border-border;

    .cross-banner {
        @apply py-ui-7 border-b-1 border-border;
    }
}
</style>
