<script>
import { createNamespacedHelpers } from 'vuex';

import { DEFAULT_DEBOUNCE_TIME } from '@configs/recommendations';
import { VIEW_TIME_TO_SEND } from '@configs/analytics-events';

import {
    PRODUCT_CARD_SELECTOR,
    PRODUCTS_LIMIT_PER_IMPRESSION,
} from '@analytics-types/Analytics';
import { IMPRESSION_VIEW } from '@analytics-types/Events';

import DataLayerProducts from '@models/Analytics/DataLayerProducts';

import { chunkBySize } from '@assets/array';
import { debounceAggregate } from '@assets/debounce-aggregate';

const { mapGetters: mapConfigGetters } = createNamespacedHelpers('config');

export default {
    name: 'WithProductImpressionViewEvent',

    props: {
        products: {
            type: Array,
            required: true,
        },

        isPageEventEmittedDeferred: {
            type: Object,
            required: true,
        },

        productsPerPage: {
            type: Number,
            default: 0,
        },

        currentPage: {
            type: Number,
            default: 0,
        },

        viewPlacementEventUrls: {
            type: Array,
            default: () => [],
        },

        attributionToken: {
            type: [String, null],
            default: null,
        },

        searchEngineOffset: {
            type: [Number, null],
            default: null,
        },
    },

    data() {
        return {
            observer: null,
            isEmittedViewPlacementEvent: false,
        };
    },

    computed: {
        ...mapConfigGetters(['currency', 'locale']),

        options() {
            return {
                root: null,
                threshold: 0.5,
                rootMargin: '0px 0px 0px 0px',
            };
        },
    },

    watch: {
        products: {
            async handler() {
                this.disconnectObserver();
                await this.$nextTick();
                this.initObserver();
            },
        },
    },

    async mounted() {
        this.initObserver();
    },

    beforeCreate() {
        this.debouncedImpressionEvent = debounceAggregate(
            products => this.emitProductImpressionViewEvent(products),
            DEFAULT_DEBOUNCE_TIME
        );
    },

    beforeDestroy() {
        this.disconnectObserver();
    },

    methods: {
        disconnectObserver() {
            if (this.observer) {
                this.observer.disconnect();
                this.observer = null;
            }
        },

        initObserver() {
            this.isEmittedViewPlacementEvent = false;

            const productCards = document.querySelectorAll(
                `[${PRODUCT_CARD_SELECTOR}]`
            );

            const productMap = new Map();

            this.observer = new IntersectionObserver((entries, observer) => {
                entries.forEach(async ({ target, isIntersecting }) => {
                    const index = parseInt(
                        target.getAttribute(PRODUCT_CARD_SELECTOR),
                        10
                    );

                    if (isIntersecting) {
                        if (productMap.has(index)) {
                            return;
                        }

                        const timeout = setTimeout(async () => {
                            observer.unobserve(target);
                            productMap.delete(index);

                            const product = this.products[index];

                            await this.isPageEventEmittedDeferred.promise;

                            this.debouncedImpressionEvent({
                                ...product,
                                index,
                            });
                        }, VIEW_TIME_TO_SEND);

                        productMap.set(index, timeout);
                    } else {
                        clearTimeout(productMap.get(index));
                        productMap.delete(index);
                    }
                });
            }, this.options);

            productCards.forEach(productCard => {
                this.observer.observe(productCard);
            });
        },

        emitProductImpressionViewEvent(productsViewed) {
            const {
                currency,
                currentPage,
                productsPerPage,
                $route: { path: routePath },
                attributionToken,
                searchEngineOffset,
            } = this;

            const productsChunks = chunkBySize(
                productsViewed.flat(),
                PRODUCTS_LIMIT_PER_IMPRESSION
            );

            const positionOffset = (currentPage - 1) * productsPerPage + 1;

            productsChunks.forEach(chunk => {
                const products = chunk.map(product => {
                    if (product.isSponsored) {
                        if (!this.isEmittedViewPlacementEvent) {
                            this.viewPlacementEventUrls.forEach(beacon =>
                                this.$services.adTech.notifyBeacon({
                                    beacon,
                                })
                            );

                            this.isEmittedViewPlacementEvent = true;
                        }

                        product.adTechEvents.onViewBeacons.forEach(beacon =>
                            this.$services.adTech.notifyBeacon({ beacon })
                        );
                    }

                    return {
                        ...product,
                        position: positionOffset + product.index,
                    };
                });

                this.$analytics.emit(IMPRESSION_VIEW, {
                    currency,
                    products: new DataLayerProducts({
                        products,
                        routePath,
                    }).build(),
                    attributionToken,
                    searchEngineOffset,
                    route: this.$route,
                });
            });
        },
    },

    render() {
        return this.$slots.default;
    },
};
</script>
