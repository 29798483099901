<template>
    <div v-if="showSizeFilter" class="cross-listing-size-filter">
        <div class="wrapper">
            <h3 class="heading">
                {{ $t('What size are you looking for?') }}
            </h3>
            <p class="subheading">
                {{
                    $t(
                        'We will show products available in the size you choose.'
                    )
                }}
            </p>
            <div class="size-pills">
                <PillSize
                    v-for="size in limitedOptions"
                    :key="size.code"
                    :selected="!!selectedOptions[size.code]"
                    @click.native="onChange(size)"
                >
                    {{ size.label }}
                </PillSize>
                <ButtonBanner @click.native="openSizeFilter()">
                    {{ $t('More sizes') }}
                </ButtonBanner>
            </div>
        </div>
        <ButtonLarge
            :variant="BUTTON_LARGE_VARIANT"
            :disabled="!couldBeApplied"
            class="submit-button"
            @click="applyFilter()"
        >
            {{ $t('Show products') }}
        </ButtonLarge>
    </div>
</template>

<script>
import { createNamespacedHelpers } from 'vuex';

import { SIZE_FILTER_CODE } from '@types/Filters';

import { DEFAULT_SEARCH_CATEGORY_NAME } from '@localeConfig/keys';
import { FILTERS_MODAL } from '@search/configs/modals';

import {
    applySearchConfiguration,
    sendAnalyticsFilterSet,
} from '@assets/filters';

import {
    ButtonLarge,
    BUTTON_LARGE_VARIANTS,
} from '@eobuwie-ui/components/ButtonLarge/v1';
import { ButtonBanner } from '@eobuwie-ui/components/ButtonBanner/v1';
import { PillSize } from '@eobuwie-ui/components/PillSize/v1';

import { SEARCH_RESULTS_PAGE_NAME } from '@search/routing/names';

const { mapState: mapCategoryState } = createNamespacedHelpers('category');

export default {
    name: 'CrossListingSizeFilter',

    components: {
        PillSize,
        ButtonBanner,
        ButtonLarge,
    },

    data() {
        return {
            selectedOptions: [],
        };
    },

    computed: {
        ...mapCategoryState(['url']),

        isSearchPage() {
            return this.$route.name === SEARCH_RESULTS_PAGE_NAME;
        },

        sizeFilter() {
            return this.$filterManager.filters.find(
                filter => filter.code === SIZE_FILTER_CODE
            );
        },

        filter() {
            return this.$filterManager.useFilter(this.sizeFilter);
        },

        showSizeFilter() {
            return (
                !!this.sizeFilter &&
                !this.sizeFilter.selected &&
                !this.$filterManager.selectedEsizemeScan?.id &&
                !this.isSearchPage &&
                this.url !==
                    this.$getLocaleConfigByKey(DEFAULT_SEARCH_CATEGORY_NAME)
            );
        },

        limitedOptions() {
            const numberOfOptions = this.filter.options.length;

            if (numberOfOptions <= 8) {
                return this.filter?.options || [];
            }

            const middleOption = Math.floor(numberOfOptions / 2);

            return (
                this.filter?.options.slice(
                    middleOption - 4,
                    middleOption + 4
                ) || []
            );
        },

        couldBeApplied() {
            return !!Object.keys(this.selectedOptions).length;
        },
    },

    beforeCreate() {
        this.BUTTON_LARGE_VARIANT = BUTTON_LARGE_VARIANTS.PRIMARY;
    },

    methods: {
        onChange(option) {
            this.filter.toggleOption(option);
            this.selectedOptions = this.filter.selectedFilterParams;
        },

        applyFilter() {
            this.filter.applyFilter();

            sendAnalyticsFilterSet(
                this,
                this.filter.code,
                `${this.filter.code}_cross`
            );
            applySearchConfiguration(this);

            this.selectedOptions = {};
        },

        openSizeFilter() {
            this.$modals.open(FILTERS_MODAL, { filter: this.filter.code });
        },
    },
};
</script>

<style scoped lang="scss">
.cross-listing-size-filter {
    @apply p-ui-6 border-b-1 border-border;
    @apply bg-ui-container-tertiary-default;

    .heading {
        @apply font-ui-header-m-bold;
    }

    .subheading {
        @apply font-ui-body-s text-ui-text-secondary-default;
    }

    .submit-button {
        @apply mt-ui-4;
    }

    .size-pills {
        @apply grid grid-cols-4 gap-ui-2 mt-ui-3;
    }

    @screen lg {
        @apply flex items-center justify-between;

        .size-pills {
            @apply flex gap-ui-2;
        }

        .submit-button {
            @apply mt-ui-0;
        }
    }
}
</style>
