<template>
    <WithWishlistedProducts :products-skus="skus" class="category">
        <div>
            <ActionDisplay
                v-if="isMarketingActionDisplay"
                :display="marketingActionDisplay"
            />

            <ContainerContent v-if="breadcrumbsExist" :align-left="true">
                <Breadcrumbs
                    :breadcrumbs="breadcrumbsList"
                    :show-last-item="false"
                    :class="breadcrumbs"
                />
            </ContainerContent>

            <PromotionWidget :is-mobile="isMobile" />

            <PageHeader
                :header-text="heading"
                :sub-header-text="subHeading"
                class="page-header"
            />

            <ContainerContentWithSidebar
                :is-sidebar-visible-on-mobile="true"
                class="page"
            >
                <template #sidebar>
                    <StickyBlockBounded
                        ref="stickyBlock"
                        relative-element-selector="#listing"
                        :enabled="!isMobile"
                        :offset="{ top: offsetTop, bottom: 0 }"
                        :scroll-sticky="true"
                    >
                        <CategoryNavigationTree />
                    </StickyBlockBounded>
                </template>
                <div
                    id="listing"
                    class="wrapper"
                    :class="{
                        'is-listing-loading': isContentLoading,
                    }"
                >
                    <Display
                        v-if="isTopListingDisplay"
                        :display="topListingDisplay"
                        class="category-display"
                    />

                    <PageBuilderInterpreter
                        v-else-if="bannerPB"
                        :components-json="bannerPB"
                        class="banner-pb"
                        tag="div"
                    />

                    <BannerHtml
                        v-else-if="bannerHTML"
                        class="banner"
                        :html="bannerHTML"
                    />

                    <SortFiltersPanel
                        :key="$route.path"
                        :is-mobile="isMobile"
                        class="category-filters-panel"
                    />
                    <Filters
                        :is-mobile="isMobile"
                        :is-pending="isContentLoading"
                    />
                    <div class="divider-wrapper">
                        <Divider :variant="DIVIDER_VARIANT" />
                    </div>
                    <RouterPagination v-if="total > 0">
                        <div
                            slot-scope="{ createPaginationPath }"
                            class="listing"
                        >
                            <WithProductImpressionClickEvent
                                #default="{ emitEvent, emitProductAdTechEvent }"
                                :products-per-page="productsPerPage"
                                :current-page="currentPage"
                            >
                                <div class="list-container">
                                    <Loader
                                        v-if="isContentLoading"
                                        class="listing-loader"
                                        height="96px"
                                    />
                                    <div class="listing-information-bar">
                                        <span
                                            :class="{
                                                'dot-visible': activeFiltersExist,
                                            }"
                                            class="products-counter"
                                        >
                                            {{ resultsCounterText }}
                                        </span>

                                        <ActiveFilters
                                            v-if="activeFiltersExist"
                                            :is-mobile="isMobile"
                                        />
                                    </div>

                                    <ProductsListing
                                        :products-per-page="productsPerPage"
                                        :products="products"
                                        :products-color-variants="
                                            productsColorVariants
                                        "
                                        :cross-listing-display="
                                            crossListingDisplay
                                        "
                                        :load-placement-event-urls="
                                            loadPlacementEventUrls
                                        "
                                        :view-placement-event-urls="
                                            viewPlacementEventUrls
                                        "
                                        :current-page="currentPage"
                                        :is-page-event-emitted-deferred="
                                            isPageEventEmittedDeferred
                                        "
                                        class="category-product-list"
                                        @product-click="emitEvent($event)"
                                        @product-right-click="
                                            emitProductAdTechEvent($event)
                                        "
                                        @mouseenter.native="loadColorVariants()"
                                    />
                                </div>
                            </WithProductImpressionClickEvent>

                            <PaginationPages
                                class="bottom-pagination"
                                :previous-button-label="$t('Previous')"
                                :next-button-label="$t('Next')"
                                :current-page="currentPage"
                                :total-pages="totalPages"
                                :create-pagination-path="createPaginationPath"
                                :is-disabled="isContentLoading"
                                @navigate-to-page="onPageChange()"
                            />
                            <Tooltip
                                position="left-top"
                                class="tooltip-wrapper"
                            >
                                <template #activator>
                                    <div class="tooltip-activator">
                                        <Icon
                                            :icon="INFO_ICON"
                                            :width="ICON_SIZE"
                                            :height="ICON_SIZE"
                                        />

                                        {{ $t('Show version id') }}
                                    </div>
                                </template>
                                <span>{{ selectedEngine }}</span>
                            </Tooltip>
                        </div>
                    </RouterPagination>

                    <InformationClearFilters
                        v-else
                        class="info-clear-filters"
                    />
                </div>
            </ContainerContentWithSidebar>

            <RecommendedProductsSliderWrapperNew
                :recommendation-type="recommendationType"
                :category-breadcrumbs="breadcrumbs"
                :placement-page-name="CATEGORY_PLACEMENT_PAGE_NAME"
                class="recommendation-slider-container"
            />

            <ContainerContent :align-left="true">
                <ListingSeoText
                    v-if="!hideSeoDescription"
                    :seo-text-content="seoDescription"
                />

                <PopularCategories
                    v-if="seoCategories?.length"
                    :categories="seoCategories"
                    :is-mobile="isMobile"
                    class="seo-categories"
                />

                <PopularBrands
                    v-if="seoBrands?.length"
                    :brands="seoBrands"
                    class="seo-brands"
                />
            </ContainerContent>
        </div>
    </WithWishlistedProducts>
</template>

<script>
import { createNamespacedHelpers, mapState } from 'vuex';
import { v4 as uuid } from 'uuid';

import { SCROLL_BEHAVIOR_CHANGED_KEY } from '@configs/storage';
import { PSB_POPULAR_CONTENT_BLOCKS_IDS } from '@configs/popular-blocks';
import { STICKY_HEADER_HEIGHT } from '@configs/header';
import { CATEGORY_PLACEMENT_PAGE_NAME } from '@configs/synerise';
import { NOT_FOUND_HTTP_CODE } from '@configs/http-codes';

import { CATALOG_PAGE_NAME } from '@search/routing/names';
import { HOME_PAGE } from '@router/paths';
import { JESSICA } from '@search/assets/search-service';

import {
    SYNERISE_RECOMMENDATION_CATEGORY_PLACEMENTS,
    WEB_EOB_CATEGORY_1,
} from '@types/Synerise';

import { DEFAULT_LOCALE } from '@analytics-types/Analytics';

import { ERROR_ACTION_TAG_NAME } from '@types/Errors';

import { isRejected } from '@assets/promise';
import { createProductsRequestParams } from '@assets/request/create-products-request-params';
import { getSearchDataResults } from '@assets/analytics-page-event';
import { getSearchData } from '@assets/search-data';
import { placeSponsoredProducts } from '@assets/category';

import { sendAnalyticsForSearchAbtest } from '@search/utils/ab-tests';

import { CATALOG_NAMESPACE } from '@models/Performance/PerformanceRecorder';

import BasePage from '@pages/mixins/BasePage';

import StickyBlockBounded from '@atoms/StickyBlockBounded/StickyBlockBounded';

import ContainerContent from '@molecules/ContainerContent/ContainerContent';
import ContainerContentWithSidebar from '@molecules/ContainerContentWithSidebar/ContainerContentWithSidebar';
import PageHeader from '@molecules/PageHeader/PageHeader';
import RouterPagination from '@molecules/RouterPagination/RouterPagination';
import WithProductImpressionClickEvent from '@molecules/WithProductImpressionClickEvent/WithProductImpressionClickEvent';
import WithWishlistedProducts from '@molecules/WithWishlistedProducts/WithWishlistedProducts';

import CategoryNavigationTree from '@search/components/organisms/CategoryNavigationTree/CategoryNavigationTree';
import PromotionWidget from '@organisms/PromotionWidget/PromotionWidget';
import RecommendedProductsSliderWrapperNew from '@organisms/RecommendedProductsSliderWrapperNew/RecommendedProductsSliderWrapperNew';

import ProductsListing from '@search-organisms/ProductsListing/ProductsListing';
import Filters from '@search-organisms/Filters/Filters';

import { Breadcrumbs } from '@eobuwie-ui/components/Breadcrumbs/v1';
import { Divider, DIVIDER_VARIANTS } from '@eobuwie-ui/components/Divider/v1';
import { Tooltip } from '@eobuwie-ui/components/Tooltip/v1';
import { Icon } from '@eobuwie/eobuwie-ui/dist/components/Icon/v1';
import { Info as InfoIcon } from '@eobuwie/eobuwie-ui/dist/icons/v1/other';
import { PaginationPages } from '@eobuwie-ui/components/PaginationPages/v1';

import {
    setDataInStore,
    areProductsEmpty,
    manageCacheForBot,
    checkEarlyReturn,
    redirectCheckCategoryUrl,
} from '@search/utils';
import {
    rebuildProducts,
    buildSeoForCatalog,
    buildListingHead,
} from '@search/utils/build';
import {
    prepareSeomaticData,
    prepareAdsDisplayData,
    prepareStaticContentData,
    prepareFiltersData,
    prepareSponsoredData,
    prepareNavigationData,
} from '@search/utils/prepare';
import { ASYNC_CATALOG_SERVICE_CONFIG } from '@search/async-services/catalog/meta';

import { prepareCatalogDataFromCorrectEngine } from '@search/utils/prepare/prepare-catalog-data-from-correct-engine';

import { prepareSeoLinksData } from '@search/utils/prepare/prepare-seo-links-data';

const {
    NAME: CATALOG_SERVICE_NAME,
    METHODS: CATALOG_SERVICE_METHODS,
} = ASYNC_CATALOG_SERVICE_CONFIG;

const { mapState: mapStatePagination } = createNamespacedHelpers('pagination');
const {
    mapState: mapCategoryState,
    mapActions: mapCategoryActions,
} = createNamespacedHelpers('category');

const { ASYNC_DATA, GET_REQUESTS } = CATALOG_NAMESPACE;

export default {
    name: 'Catalog',

    key: 'category',

    components: {
        Breadcrumbs,
        CategoryNavigationTree,
        ProductsListing,
        ContainerContent,
        ContainerContentWithSidebar,
        Divider,
        PageHeader,
        PaginationPages,
        RecommendedProductsSliderWrapperNew,
        RouterPagination,
        StickyBlockBounded,
        WithProductImpressionClickEvent,
        WithWishlistedProducts,
        PromotionWidget,
        Tooltip,
        Icon,
        Filters,
        SortFiltersPanel: () => ({
            component: import(
                // eslint-disable-next-line max-len
                /* webpackChunkName: "sort-filters-panel" */ '@organisms/SortFiltersPanel/SortFiltersPanel'
            ),
        }),

        Loader: () => ({
            component: import(
                /* webpackChunkName: "loader" */ '@atoms/Loader/Loader'
            ),
        }),

        PageBuilderInterpreter: () => ({
            component: import(
                // eslint-disable-next-line max-len
                /* webpackChunkName: "page-builder-interpreter" */ '@modules/page-builder/components/PageBuilderInterpreter/PageBuilderInterpreter'
            ),
        }),

        ActionDisplay: () => ({
            component: import(
                /* webpackChunkName: "action-display" */ '@molecules/ActionDisplay/ActionDisplay'
            ),
        }),

        Display: () => ({
            component: import(
                /* webpackChunkName: "display" */ '@molecules/Display/Display'
            ),
        }),

        BannerHtml: () => ({
            component: import(
                /* webpackChunkName: "banner-html" */ '@organisms/BannerHtml/BannerHtml'
            ),
        }),

        InformationClearFilters: () => ({
            component: import(
                // eslint-disable-next-line max-len
                /* webpackChunkName: "information-clear-filters" */ '@organisms/InformationClearFilters/InformationClearFilters'
            ),
        }),

        ActiveFilters: () => ({
            component: import(
                /* webpackChunkName: "active-filters" */ '@organisms/ActiveFilters/ActiveFilters'
            ),
        }),

        ListingSeoText: () => ({
            component: import(
                // eslint-disable-next-line max-len
                /* webpackChunkName: "listing-seo-text" */ '@organisms/ListingSeoText/ListingSeoText'
            ),
        }),

        PopularCategories: () => ({
            component: import(
                // eslint-disable-next-line max-len
                /* webpackChunkName: "popular-categories" */ '@molecules/PopularCategories/PopularCategories'
            ),
        }),

        PopularBrands: () => ({
            component: import(
                /* webpackChunkName: "popular-brands" */ '@molecules/PopularBrands/PopularBrands'
            ),
        }),
    },

    mixins: [BasePage({ sendPageEvent: false })],

    layout: () => 'default',

    middleware: [
        ({ $filterManager }) => $filterManager.registerFilterStoreModule(),
        'global/parse-route-query',
        'parse-category-route-url',
        'category-custom-scroll-behavior',
    ],

    scrollToTop: true,

    async asyncData({
        app: {
            $absoluteUrl,
            $abTests,
            $asyncServices,
            $createCategoryPath,
            $errorHandler,
            $fetchInParallel,
            $filterManager,
            $performanceRecorder,
            $getLocaleConfigByKey,
            $services,
            $ua,
            $t,
        },
        store,
        route,
        error,
        query: requestQuery,
        redirect,
        req,
        res,
        from,
    }) {
        $performanceRecorder.start(ASYNC_DATA);

        const cachedListing = manageCacheForBot(process, req, res);

        const {
            pagination,
            sort,
            category: { url },
            filters: { urlFilters },
            config: {
                storeView: { base_currency_code: currency, locale },
            },
        } = store.state;

        const { shouldReturnEarly } = checkEarlyReturn({ from, store, route });

        if (shouldReturnEarly) {
            return;
        }

        const searchApiRequestParams = createProductsRequestParams({
            pagination,
            sort,
            filters: urlFilters,
            categoryUrl: url,
            currency,
            locale,
        });

        $performanceRecorder.start(GET_REQUESTS);
        const requests = [
            prepareSeomaticData({
                $asyncServices,
                $errorHandler,
                route,
                redirect,
                req,
            }),
            prepareCatalogDataFromCorrectEngine({
                $asyncServices,
                $errorHandler,
                $abTests,
                route,
                req,
                requestQuery,
                redirect,
                searchRequestParams: searchApiRequestParams,
                urlFilters,
                locale,
            }),
            prepareFiltersData({
                $asyncServices,
                $errorHandler,
                searchRequestParams: searchApiRequestParams,
            }),
            prepareNavigationData({
                $asyncServices,
                $errorHandler,
                searchRequestParams: searchApiRequestParams,
            }),
            prepareSeoLinksData({
                $asyncServices,
                $errorHandler,
                params: { locale, categorySlug: url },
            }),
        ];

        if (!cachedListing) {
            requests.push(
                prepareSponsoredData({
                    $asyncServices,
                    $errorHandler,
                    $ua,
                    searchRequestParams: searchApiRequestParams,
                })
            );

            const {
                params: { url: categoryParam = '' },
            } = route;

            if (categoryParam.length) {
                requests.push(
                    prepareStaticContentData({
                        $asyncServices,
                        $errorHandler,
                        $performanceRecorder,
                        route,
                    })
                );
            }

            if (url) {
                requests.push(
                    prepareAdsDisplayData({
                        $services,
                        $errorHandler,
                        $ua,
                        store,
                        route,
                    })
                );
            }
        }

        requests.push(
            store.dispatch('cmsBlock/getCmsBlocks', {
                cmsBlocksToLoad: PSB_POPULAR_CONTENT_BLOCKS_IDS,
            })
        );

        const [
            seomaticPromise,
            productsCatalogDataPromise,
            filtersDataPromise,
            navigationDataPromise,
            seoLinksDataPromise,
            sponsoredDataPromise,
            staticContentPromise,
            adsDisplayPromise,
        ] = await $fetchInParallel(requests);

        $performanceRecorder.end(GET_REQUESTS);

        if (isRejected(productsCatalogDataPromise)) {
            const err = productsCatalogDataPromise.reason;

            return error({
                statusCode: err.statusCode || 500,
                message: err.message || 'Error getting category.',
            });
        }

        const {
            seomaticName = '',
            seomaticSubHeading = '',
            seomaticMeta = {},
            seomaticCanonicalUrl,
        } = seomaticPromise.value || {};

        const { filters = {} } = filtersDataPromise?.value || {};

        const { navigation = {}, category = null, breadcrumbs = [] } =
            navigationDataPromise?.value || {};

        const {
            brands: seoLinksBrands = [],
            categories: seoLinksCategories = [],
        } = seoLinksDataPromise?.value || {};

        const {
            catalogProducts = [],
            total = null,
            totalFormatted = null,
            searchQuery = {},
            sortOptions = [],
        } = productsCatalogDataPromise?.value || {};

        redirectCheckCategoryUrl({
            $createCategoryPath,
            redirect,
            requestQuery,
            urlFilters,
            url,
            locale,
            category,
        });

        if (process.server && total === 0) {
            res.statusCode = NOT_FOUND_HTTP_CODE;
        }

        const {
            sponsoredProducts = [],
            loadPlacementEventUrls = [],
            viewPlacementEventUrls = [],
        } = sponsoredDataPromise.value || {};

        const mergedProducts =
            placeSponsoredProducts({
                products: catalogProducts,
                sponsoredProducts,
            }) || [];

        const products = await rebuildProducts({
            $getLocaleConfigByKey,
            $performanceRecorder,
            products: mergedProducts,
            categories: [breadcrumbs],
            locale,
        });

        areProductsEmpty({ error, products, total });

        const { bannerHTML = '', seoDescription = '', bannerPB = '' } =
            staticContentPromise?.value || [];

        const {
            marketingActionDisplayData = {},
            topListingDisplayData = {},
            crossListingDisplayData = {},
        } = adsDisplayPromise?.value || {};

        $filterManager.initManager({
            filters,
            searchQueryFilters: searchQuery.filters || {},
            searchQueryLocalized: searchQuery.localized_filters || {},
            searchQueryLabels: searchQuery.filter_labels || {},
            rangeBoundaries:
                store.getters['esizeme/esizemeFilterRangeBoundaries'],
            initial: true,
        });

        const {
            totalPages,
            seoMeta,
            seoLinks,
            heading,
            subHeading,
        } = buildSeoForCatalog({
            $createCategoryPath,
            $absoluteUrl,
            $filterManager,
            $errorHandler,
            $t,
            store,
            category,
            route,
            total,
            navigation,
            breadcrumbs,
            limit: searchQuery.limit,
            seomaticName,
            seomaticSubHeading,
            seomaticMeta,
            seomaticCanonicalUrl,
            currentPage: searchQuery.page,
            filterLabels: searchQuery.filter_labels,
            urlFilters,
        });

        const breadcrumbsData = breadcrumbs.map(item => {
            const itemTranslations = item.translations[locale] || {};

            return {
                name: itemTranslations.label || '',
                path: itemTranslations.url
                    ? $createCategoryPath(itemTranslations.url)
                    : '',
            };
        });

        const { skus = [] } = setDataInStore({
            store,
            route,
            sortOptions,
            page: searchQuery.page,
            totalPages,
            products,
            navigation,
            total,
        });

        if (breadcrumbs.length) {
            const lastCategory = breadcrumbs.at(-1);

            store.dispatch(
                'product/setListingCategoryId',
                lastCategory?.id || ''
            );
        }

        $performanceRecorder.end(ASYNC_DATA);

        return {
            seoMeta,
            seoLinks,
            heading,
            subHeading,
            skus,
            products,
            navigation,
            url,
            productsPerPage: searchQuery.limit,
            total,
            totalFormatted,
            filters,
            bannerHTML,
            bannerPB,
            marketingActionDisplay: marketingActionDisplayData,
            topListingDisplay: topListingDisplayData,
            crossListingDisplay: crossListingDisplayData,
            seoDescription,
            breadcrumbs: breadcrumbsData,
            firstProduct: products.length ? products[0] : null,
            loadPlacementEventUrls,
            viewPlacementEventUrls,
            seoBrands: seoLinksBrands,
            seoCategories: seoLinksCategories,
        };
    },

    data() {
        return {
            productsColorVariantsLoaded: false,
            productsColorVariantsLoadingError: false,
            productsColorVariants: {},
            selectedEngine: null,
        };
    },

    head() {
        const {
            seoMeta,
            seoLinks,
            firstProduct,
            breadcrumbs,
            $route,
            $absoluteUrl,
            $getMetaTagRobotsContent,
        } = this;

        return buildListingHead({
            seoMeta,
            seoLinks,
            firstProduct,
            breadcrumbs,
            $route,
            $absoluteUrl,
            $getMetaTagRobotsContent,
        });
    },

    computed: {
        ...mapState(['isMobile', 'sort']),

        ...mapStatePagination(['currentPage', 'totalPages']),

        ...mapCategoryState(['isContentLoading']),

        breadcrumbsList() {
            return [
                {
                    name: this.$t('Home page'),
                    path: HOME_PAGE,
                },
                ...this.breadcrumbs.slice(1),
            ];
        },

        breadcrumbsExist() {
            return this.breadcrumbs?.length > 1;
        },

        isMarketingActionDisplay() {
            return Object.keys(this.marketingActionDisplay).length;
        },

        isTopListingDisplay() {
            return Object.keys(this.topListingDisplay).length;
        },

        resultsCounterText() {
            return `${this.$t('Products')}: ${this.totalFormatted}`;
        },

        recommendationType() {
            return WEB_EOB_CATEGORY_1;
        },

        hideSeoDescription() {
            const {
                seoDescription,
                currentPage,
                sort: { orderCode, orderDir } = {},
            } = this;

            return (
                !seoDescription || currentPage !== 1 || (orderCode && orderDir)
            );
        },

        activeFiltersExist() {
            return (
                !!this.$filterManager.selectedFiltersCount ||
                !!this.$filterManager.selectedExtraFiltersCount
            );
        },

        syneriseCampaignIds() {
            const { locale } = this;

            return SYNERISE_RECOMMENDATION_CATEGORY_PLACEMENTS.map(placement =>
                this.$services.recommendations.getCampaignIdByType(
                    placement,
                    locale
                )
            );
        },

        offsetTop() {
            return 120;
        },
    },

    watch: {
        $route() {
            this.$nextTick(async () => {
                await this.atRouteChange();
            });
        },
    },

    watchQuery: true,

    beforeCreate() {
        this.$store.dispatch('setRouteRenderStart', {
            name: 'catalog',
            timestampStart: new Date().getTime(),
        });

        this.$filterManager.registerFilterStoreModule();

        this.INFO_ICON = InfoIcon;
        this.ICON_SIZE = '16px';
        this.DIVIDER_VARIANT = DIVIDER_VARIANTS.M;
        this.CATEGORY_PLACEMENT_PAGE_NAME = CATEGORY_PLACEMENT_PAGE_NAME;
    },

    async mounted() {
        await this.isCustomerFetchedDeferred.promise;

        this.atRouteChange();
    },

    methods: {
        ...mapCategoryActions(['setLastUrl', 'setUrl', 'setLoading']),

        async atRouteChange() {
            this.setLoading(false);
            this.sendAnalyticsEvents();

            const engineVariant = JESSICA;
            const newID = uuid().slice(0, -13);

            this.selectedEngine = engineVariant.at(0) + newID || null;
        },

        getCategoryUrl(locale = this.locale) {
            try {
                const { categories = [] } = this.navigation || {};

                if (categories && categories.length && categories[0]) {
                    return categories[0].translations[locale]?.url || '';
                }
            } catch (err) {
                this.$errorHandler.captureError(err, {
                    [ERROR_ACTION_TAG_NAME]: 'getCategoryUrl',
                });

                return '';
            }
        },

        async loadColorVariants() {
            if (
                this.productsColorVariantsLoaded ||
                this.productsColorVariantsLoadingError ||
                this.isMobile ||
                Object.keys(this.productsColorVariants).length
            ) {
                return;
            }

            const { locale, currency, products } = this;
            const colorVariantValues = products
                .filter(product => {
                    const { colorVariantsCount } = product;

                    return !colorVariantsCount || colorVariantsCount > 1;
                })
                .map(product => product.colorVariantsLink.value)
                .filter(item => item);

            if (colorVariantValues.length === 0) {
                return;
            }

            try {
                const {
                    colorVariantsLink: { url: colorVariantsUrl },
                } = products.find(({ colorVariantsLink: { url } }) => !!url);

                const colorVariants = await this.$asyncServices.use(
                    CATALOG_SERVICE_NAME,
                    CATALOG_SERVICE_METHODS.GET_COLOR_VARIANTS,
                    {
                        colorVariantsUrl,
                        colorVariantValues,
                        locale,
                        currency,
                    }
                );

                if (!colorVariants.length) {
                    this.productsColorVariantsLoaded = true;

                    return;
                }

                const grouped = {};

                colorVariants.forEach(variant => {
                    const variantLink = variant.colorVariantsLink.value;

                    if (!grouped[variantLink]) {
                        grouped[variantLink] = [];
                    }

                    grouped[variantLink].push(variant);
                });

                this.productsColorVariants = grouped;
            } catch (err) {
                this.productsColorVariantsLoadingError = true;
                this.$errorHandler.captureError(err, {
                    [ERROR_ACTION_TAG_NAME]: 'loadColorVariants',
                });
            }
        },

        onPageChange() {
            this.$storage.setItem(SCROLL_BEHAVIOR_CHANGED_KEY, true);
            this.$scrollTo('main', {
                offset: this.isMobile ? -STICKY_HEADER_HEIGHT : 0,
            });
        },

        sendAnalyticsEvents() {
            const categoryUrlDefaultLocale = this.getCategoryUrl(
                DEFAULT_LOCALE
            );

            let routeDefaultLocale = this.$route;

            if (categoryUrlDefaultLocale) {
                const categoryRoute = this.$router.resolve({
                    name: CATALOG_PAGE_NAME,
                    params: {
                        url: categoryUrlDefaultLocale,
                    },
                });

                routeDefaultLocale = categoryRoute.route;
            }

            const searchData = getSearchData(
                this.$filterManager.selectedCodes,
                this.$filterManager.availableCodes,
                this.$filterManager.selectedExtraParams,
                this.sort
            );

            this.emitPageEvent({
                route: routeDefaultLocale,
                searchData: {
                    ...searchData,
                    ...getSearchDataResults({
                        route: routeDefaultLocale,
                        searchResults: this.total,
                    }),
                },
                syneriseCampaignIds: this.syneriseCampaignIds,
            });

            const { $cookies, $abTests, $analytics } = this;

            sendAnalyticsForSearchAbtest({ $cookies, $abTests, $analytics });
        },
    },
};
</script>

<style lang="scss" scoped>
$loader-top-margin: -96px;

.category {
    .page {
        @apply mt-3;
    }

    .category-filters-panel {
        @apply mt-12p;
    }

    .category-display {
        @apply my-12p;
    }

    .banner {
        @apply mt-12p;
    }

    .banner-pb {
        @apply -mx-3 mt-12p;
    }

    .bottom-pagination {
        @apply mb-5 mt-ui-8;
    }

    .info-clear-filters {
        @apply my-3;
    }

    .recommendation-slider-container {
        @apply mt-5;
    }

    .products-count {
        @apply mt-12p;
    }

    .category-product-list {
        @apply mt-4;
    }

    .divider-wrapper {
        @apply -mx-3 mt-3;
    }

    .wrapper {
        @apply relative;
    }

    .is-listing-loading {
        &:before {
            @apply bg-light absolute opacity-50 z-2 w-full h-full;
            content: '';
        }
    }

    .listing-information-bar {
        @apply flex gap-2 justify-start pt-4 items-center h-7;
        @apply px-3;
    }

    .listing {
        @apply -mx-ui-4;
    }

    .products-counter {
        @apply font-ui-body-m text-dark;
    }

    .dot-visible {
        @apply flex items-center;

        &:after {
            @apply text-ui-swap-gray900 ml-2;
            content: '·';
        }
    }

    .listing-loader {
        @apply z-2 bg-green6 rounded-50 w-8 h-8;
        @apply w-9 sticky top-1/2 inset-x-0 mx-auto;
        margin-top: $loader-top-margin;
    }

    .seo-categories {
        @apply pb-5 mt-5 border-b-1 border-gray4;
    }

    .seo-brands {
        @apply mt-5 mb-5;
    }

    .tooltip-wrapper {
        @apply font-ui-body-s py-ui-2 z-1 mx-ui-4;
    }

    .tooltip-activator {
        @apply flex items-center gap-x-1;
    }

    :deep(.category-display) {
        .display-template {
            @apply w-screen max-w-fit -mx-3;
        }
    }

    @screen mobile-and-tablet-only {
        .category-tree {
            @apply min-h-xl -mr-3;
        }

        .is-listing-loading:before {
            @apply w-screen -ml-3;
        }
    }

    @screen md {
        :deep(.category-display) {
            .display-template {
                @apply w-full max-w-full mx-0;
            }
        }
    }

    @screen lg {
        @apply relative;

        .divider-wrapper {
            @apply hidden;
        }

        .breadcrumbs {
            @apply my-ui-2;
        }

        .page-header {
            @apply mt-ui-2;
        }

        .listing-information-bar {
            @apply px-0 mx-0 pt-ui-1;
        }

        .listing {
            @apply mx-ui-0;
        }

        .products-count {
            @apply mt-3;
        }

        .category-product-list {
            @apply mt-4;
        }

        .category-filters-panel {
            @apply mt-0;
        }

        .category-display {
            @apply mt-0 mb-12p;
        }

        .banner {
            @apply mt-0 mb-12p;
        }

        .banner-pb {
            @apply mx-0 mt-0 mb-12p;
        }

        .bottom-pagination {
            @apply mb-9 mx-ui-auto;
        }

        .info-clear-filters {
            @apply my-ui-9;
        }

        .heading {
            @apply uppercase;
        }

        .recommendation-slider-container {
            @apply mt-9 mb-7;
        }

        .category-tree {
            @apply min-h-0 mr-0 mb-12p;
        }

        .seo-categories {
            @apply pb-6 mt-6;
        }

        .seo-brands {
            @apply mt-6 mb-6;
        }

        .tooltip-wrapper {
            @apply w-1/3 mx-0;
        }

        &:deep() {
            .sidebar {
                @apply pr-4;
            }

            .container-with-sidebar.is-sidebar-visible-on-mobile .sidebar {
                @apply z-2;
            }

            .loader {
                @apply w-8 h-8;
            }
        }
    }
}
</style>
