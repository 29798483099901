import { ERROR_ACTION_TAG_NAME } from '@types/Errors';

import { ASYNC_CATALOG_SERVICE_CONFIG } from '@search/async-services/catalog/meta';

const {
    NAME: CATALOG_SERVICE_NAME,
    METHODS: CATALOG_SERVICE_METHODS,
} = ASYNC_CATALOG_SERVICE_CONFIG;

export const prepareSeoLinksData = async ({
    $asyncServices,
    $errorHandler,
    params,
}) => {
    const { data, error, status } = await $asyncServices.use(
        CATALOG_SERVICE_NAME,
        CATALOG_SERVICE_METHODS.GET_SEO_LINKS_DATA,
        params
    );

    if (!error && data) {
        const { categories = [], brands = [] } = data;

        return {
            categories,
            brands,
        };
    }

    const errorToThrow = new Error(error?.message || 'UNKNOWN_ERROR', {
        cause: error,
    });

    $errorHandler.captureError(
        errorToThrow,
        {
            [ERROR_ACTION_TAG_NAME]: 'getSeoLinkData',
        },
        {
            requestParams: params,
            data,
            error,
            status,
        }
    );

    return { categories: [], brands: [] };
};
